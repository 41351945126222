import { sign } from 'tweetnacl';
import { Keypair, Signer, Transaction } from './solana';

export let Buffer = require("buffer").Buffer
export * from './wallets';
export * from './solana';
export * from './spl-token';

export function signTransaction(transaction: Transaction, signer: Signer): Transaction {
    transaction.sign(signer);
    return transaction;
}

export function createSignature(transactionBuffer: Uint8Array, kp: Keypair): Uint8Array {
    return sign.detached(transactionBuffer, kp.secretKey);
}
