export * from '@solana/wallet-adapter-wallets';

export * from '@solana/wallet-adapter-base';

/*
export interface WalletAdapterEvents {
    connect(publicKey: PublicKey): void;
    disconnect(): void;
    error(error: WalletError): void;
    readyStateChange(readyState: WalletReadyState): void;
}
 */

// The 'connect' event is emitted after the wallet has successfully connected.
export function setupWalletOnConnectEvent(walletAdapter, onConnect) {
  walletAdapter.on('connect', onConnect);
}

// The 'disconnect' event is emitted after the wallet has been disconnected.
export function setupWalletOnDisconnectEvent(walletAdapter, onDisconnect) {
  walletAdapter.on('disconnect', onDisconnect);
}

// The 'error' event is emitted when an error occurs while interacting with the wallet.
export function setupWalletOnErrorEvent(walletAdapter, onError) {
  walletAdapter.on('error', onError);
}

// Removes 'connect' event listener
export function removeWalletOnConnectEvent(walletAdapter, onConnect) {
  walletAdapter.off('connect', onConnect);
}

// Removes 'disconnect' event listener
export function removeWalletOnDisconnectEvent(walletAdapter, onDisconnect) {
  walletAdapter.off('disconnect', onDisconnect);
}

// Removes 'error' event listener
export function removeWalletOnErrorEvent(walletAdapter, onError) {
  walletAdapter.off('error', onError);
}